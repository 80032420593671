/**
 * Prevents newlines in HTML editable text fields
 *
 * @param {HTMLElement} textEl
 */
export const breakless = (textEl) => {
  const parseBreaks = (el) => {
    el.value = el.value.replace(/[\n\r]/g, "")
  }

  parseBreaks(textEl)

  textEl.addEventListener("keydown", (e) => {
    if (e.code === "Enter") e.preventDefault()
  })

  textEl.addEventListener("change", (e) => {
    parseBreaks(e.target)
  })
}
