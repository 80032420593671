export const initClearableImageInput = () => {
  const inputFieldsets = document.querySelectorAll(".clearable-image-input")

  inputFieldsets.forEach((fieldset) => {
    const selectFileButton = fieldset.querySelector(
      ".clearable-image-input__input button",
    )
    const fileInput = fieldset.querySelector(
      ".clearable-image-input__input input",
    )
    const filename = fieldset.querySelector(
      ".clearable-image-input__input .filename",
    )

    const deleteButton = fieldset.querySelector(
      ".clearable-image-input__delete button",
    )
    const deleteInput = fieldset.querySelector(
      ".clearable-image-input__delete input",
    )

    const imageContainer = fieldset.querySelector(
      ".clearable-image-input__preview",
    )

    if (selectFileButton)
      selectFileButton.onclick = () => {
        fileInput.click()
      }

    if (deleteButton)
      deleteButton.onclick = () => {
        deleteInput.click()
      }

    if (fileInput)
      fileInput.onchange = () => {
        filename.textContent = fileInput.value
          ? fileInput.value.replace("C:\\fakepath\\", "")
          : "keine Datei ausgewählt"
      }

    if (deleteInput)
      deleteInput.onchange = (e) => {
        if (e.target.checked) {
          selectFileButton.disabled = true
          imageContainer.classList.add("disabled")
          deleteButton.classList.add("checked")
          fileInput.value = ""
          filename.textContent = ""
        } else {
          selectFileButton.disabled = false
          imageContainer.classList.remove("disabled")
          deleteButton.classList.remove("checked")
        }
      }
  })
}
