export function initOrderToggle() {
  const radioToggles = document.querySelectorAll(
    ".shipping-address-toggle input",
  )
  const container = document.querySelector(".shipping-address-container")

  function checkToggles() {
    radioToggles.forEach((toggle) => {
      if (toggle.checked) {
        const inputs = container.querySelectorAll("input")
        if (toggle.value == 1) {
          container.classList.remove("hide")

          inputs.forEach((input) => {
            input.value = ""
            input.setAttribute("required", "")
          })
        } else {
          container.classList.add("hide")
          inputs.forEach((input) => {
            input.removeAttribute("required")
          })
        }
      }
    })
  }

  if (container && radioToggles) {
    checkToggles()
    radioToggles.forEach((toggle) => {
      toggle.onchange = (_e) => {
        checkToggles()
      }
    })
  }
}
